<template>
  <div class="page">
    <div class="s2 p-card fs-16 m-3">
      <div class="flex h-between p-3 pointer">
        <div>关键词回复</div>
        <div>
          <input v-model="status" type="checkbox" class="switch" @change="statusChange">
        </div>
      </div>
      <div class="divider-dashed ml-3" />
      <div class="b2">
        <no-data v-if="list.length===0" title="暂未添加关键词" btn-text="新增关键词" @click="goEdit(0)" />
        <div v-for="(item,index) in list" :key="index" class="item">
          <div class="flex h-between">
						<div><b class="color-primary">·{{'关键词'+(index+1)}}</b></div>
						<div class="flex">
							<div class="p-icon-edit" @click="toEditKey(item)"></div>
							<div class="p-icon-delete ml-1" @click="delItem(item,index)"></div>
						</div>
					</div>
          <div class="tags">
            <div v-for="item2 in item.keywords" :key="item2" class="tag">{{ item2 }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="p-footer h-end" v-if="list.length > 0">
      <div class="flex  flex-right">
          <button class="btn auto fill ml-3" @click="goEdit(0)">新增关键词</button>
        </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'WelcomeWords',
  components: {},
  data() {
    return {
      status: false,
      chatroomList: [],
      contentMsgs: [],
      list: []
    }
  },
  computed: {
    ...mapGetters(['globalData'])
  },
  created() {
    this.clearContent()
    this.getData()
  },
  methods: {
    clearContent() {
      this.$store.commit('resetContentData', {})
      this.$store.commit('resetGlobalData', {})
    },
    getData() {
      this.$get('/KeywordReply/GetKeywordReplys').then(res => {
        this.list = res.keywordList
        this.status = res.status === 1
      })
    },
    statusChange() {
      this.$get('/KeywordReply/KeywordReplyStatusSetting', {
        status: this.status ? 1 : 0
      }).then(res => {
        this.$Toast('切换成功')
      })
    },
    goGroup() {
      this.$store.commit('setGlobalData', {
        chatroomList: this.chatroomList.map(i => i.chatroomId)
      })
      this.$router.push('/selectGroup')
    },
    goEdit(n) {
      this.$router.push({
        path: '/keywordsEdit'
      })
    },
    toEditKey(item) {
      this.$router.push({
        path: '/keywordsEdit',
        query: { id: item.keywordId }
      })
    },
    async delItem(item) {
      const _this = this
      await _this.$get('/KeywordReply/KeywordReplyDelete', {
        keywordId: item.keywordId
      }).then(res => {
        _this.$Toast('删除成功')
      })
      _this.getData()
    }
  }
}
</script>

<style scoped lang="scss">
@import "index";
</style>
